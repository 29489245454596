nav {
  top: 0;
  z-index: 100;
  padding-top:20px;
  padding-bottom:20px;

}

.links-wrapper {
 
  display: flex;
  justify-content:space-around;
}
.links-wrapper a{
  text-decoration: none;

}

.links-wrapper .links a{
  font-family: GlacialIndifferenceBold;
  font-size: 1.2em;
  margin-right: 30px;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: 3px;

}


/*Small Screens  */
@media screen and (max-width: 800px) {
  .links-wrapper .links a{
    font-size: 1em;
    margin-right: 10px;
  }
}