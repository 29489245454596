@font-face {
  font-family: "GlacialIndifference";
  src: url("./fonts/GlacialIndifference.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GlacialIndifferenceBold";
  src: url("./fonts/GlacialIndifference-Bold.otf");
  font-weight: 400;
  font-style: normal;
}

/* Variables */
:root {
  --mainColor: #fff;
  --secondaryColor: transparent;
  --accentColor: #dc2743;
  --mainText: black;
  --secondaryText: #4b5156;
  --buttonColor: black;
}

/* Global Styles */
html,
body,
p {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "GlacialIndifference";
}
.mobile-horizontal-scroll {
  display: none;
}
.horizontal-scroll {
  display: block;
}
.about a {
  color: var(--accentColor);
}
a {
  color: var(--mainText);
}

h1,
h2 {
  padding: 0;
  margin: 0;
}

h1,
h2 {
  font-family: GlacialIndifferenceBold;
}

.section-heading {
  font-family: GlacialIndifferenceBold;
  font-size: 3em;
  padding: 0;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.img-responsive {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.main-container {
  width: 90%;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}
.text {
  font-family: GlacialIndifference;
}

.video-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  -webkit-box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
  background-color: white;
  border-radius: 5%;
  margin: 13px;
  min-height: 289px;
}
.video-card h4 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.video-text {
  max-width: 26ch;
  padding-left: 1ch;
  font-size: 0.9em;
  font-family: "GlacialIndifference";
  line-height: 1.5em;
  font-weight: 500;
  padding-bottom: 15px;
}

.video-card img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 16em;
}
.video-button-pre {
  font-family: PlaylistScript;
  font-size: 17px;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.scroll-menu-arrow {
  cursor: pointer;
}
.scroll-menu-arrow :hover {
  opacity: 0.8;
}

.scroll-menu-arrow pre {
  text-decoration: none;
  display: inline-block;
  padding: 12px 16px;
  border-radius: 50%;
  background-color: #dc2743;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  object-fit: contain;
}

.menu-item-wrapper:focus {
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0px;
}

.link-1 {
  transition: 0.3s ease;
  background: transparent;
  color: #000;
  padding: 20px 0;
  margin: 0 20px;
}

.link-1:hover {
  cursor: pointer;
  border-top: 4px solid #ffffff;
  border-bottom: 4px solid #ffffff;
  padding: 6px 0;
  text-shadow: 4px 4px 4px #fff;
}

.watch-button {
  border: 1px solid var(--accentColor);
  border-radius: 25px;
  color: var(--accentColor);
  display: inline-flex;
  cursor: pointer;
  padding: 12px 16px 5px 16px;
  text-decoration: none;
  color: #ffffff;
  background-color: var(--accentColor);
}
.watch-button:hover {
}
.watch-button:active {
  position: relative;
  top: 1px;
}

.watch-button .fa-youtube {
  font-size: 30px;
}

.scroll-menu-arrow--disabled {
  opacity: 0.3;
}
.section-intro {
  margin-bottom: 30px;
}
.menu-wrapper {
  margin-right: 14px;
}

.s2 {
  padding-top: 40px;
  border-bottom: 1px solid #efefef;
}

.s3 {
  background: #ffffff;
  padding-top: 25px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.numbers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  gap: 50px;
  justify-content: center;
  padding-bottom: 50px;
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 50px;
  justify-content: center;
  padding-bottom: 50px;
}
.brands-grid img {
  height: 60px;
  object-fit: contain;
}
.brands-grid div {
  text-align: center;
}

.footer {
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  padding: 21px;
}
.footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.footer h1 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.footer-links {
  padding-left: 0;
}
.footer-links li {
  list-style-type: none;
  display: block;
}
footer a {
  color: #737373;
  text-decoration: none;
}
footer a:active,
footer a:focus,
footer a:hover {
  color: var(--accentColor);
  text-decoration: none;
}

.section-intro {
  padding-top: 15px;
  padding-bottom: 30px;
}
.section-about {
  padding-top: 3%;
  padding-bottom: 30px;
  background-color: #ff9aa2;
  background: url("./Home/images/bg-cover-2.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.intro-wrapper {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-areas:
    "left-column right-column"
    "left-column right-column2";
}

.img-pari {
  /* -ms-transform: rotate(-2deg); /* IE 9 
transform: rotate(-2deg); */
  padding-top: 10px;
  box-shadow: 10px 15px 5px #808080;
  -moz-box-shadow: 10px 15px 5px #808080;
  -webkit-box-shadow: 10px 15px 5px #808080;
  -khtml-box-shadow: 10px 15px 5px #808080;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.left-column {
  grid-area: left-column;
  text-align: center;
}

.right-column {
  grid-area: right-column;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  text-align: center;
}
.right-column2 {
  grid-area: right-column2;
  padding-left: 40px;
  padding-top: 29px;
  padding-right: 40px;
  overflow: hidden;
  /* transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -70, 0, 1); */
}

.social-links {
  display: flex;
  justify-content: space-evenly;
  padding: 0 4em;
}

.social-links a {
  text-decoration: none;
}
.social-links a:focus {
  outline: none;
}
.ic-youtube {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.social-links a:hover {
  -webkit-transform: scale(0.93);
  -moz-transform: scale(0.93);
  -ms-transform: scale(0.93);
  transform: scale(0.93);
}

.cap {
  padding: 10px;
}
.form-response {
  padding: 10px;
}
.form-response .success-text {
  color: #288c28;
  font-size: 1.4em;
  font-family: GlacialIndifferenceBold;
}
.form-response .error-text {
  color: #ff0000;
  font-size: 1.4em;
  font-family: GlacialIndifferenceBold;
}
.input-feedback {
  font-size: 0.7em;
  color: var(--accentColor);
}
.error-input {
  border: 2px solid var(--accentColor) !important;
}
.contact-section {
  background-color: #dfdbe5;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.contact-section-wrapper {
  display: grid;
  padding-top: 20px;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "contact-left-div contact-right-div";
}
.contact-left-div {
  grid-area: contact-left-div;
}
.contact-right-div {
  grid-area: contact-right-div;
  margin-top: 10vh;
}

.form {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #d9d8d8;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--mainColor);
  margin-bottom: 50px;
  /*   
  -webkit-box-shadow: -2px 7px 21px -9px #000000bf;
  -moz-box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75); */
}
.form-elements {
}

.form-elements .form-element {
  border: 1px solid #d9d8d8;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 30px;
}

.about {
  font-family: "GlacialIndifference";
  line-height: 1.9em;
  font-size: 1.1em;
  font-weight: 500;
  margin: 0;
  text-align: center;
  display: block;
}
.about p {
  max-width: 60ch;
  display: inline-block;
  text-align: justify;
}

.contact-button {
  background-color: #dc2743;
  border-radius: 6px;
  border: 1px solid #dc2743;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: GlacialIndifference;
  font-size: 17px;
  letter-spacing: 0.1em;
  opacity: 0.9;
  padding: 16px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.contact-button:hover {
  opacity: 1;
}
.contact-button:active {
  position: relative;
  top: 1px;
}

.s2 .help-text {
  display: none;
}

/* About Component CSS*/
.about-section-body {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  grid-template-areas: "left right";
}
.rounded {
  border-radius: 25px;
}
.about-section-body .left {
  grid-area: left;
  text-align: center;
}
.about-section-body .right {
  grid-area: right;
  background-color: #ffffff;
  padding: 6%;
  margin: 1%;
}

.about-section-body .child p {
  text-align: justify;
}

/* Large Screens */
@media screen and (min-width: 2000px) {
  .main-container {
    width: 2000px;
  }
}

/* Medium Screens */
@media screen and (max-width: 1200px) {
  .about {
    line-height: 1.4em;
    font-size: 1em;
  }

  .main-container {
    width: 95%;
  }
  .s2 .main-container {
    width: 100%;
  }

  .s2 {
    padding: 10px !important;
  }

  .video-card img {
    width: 13em !important;
  }
  .projects {
    margin: 5px !important;
    padding: 5px !important ;
  }
  .scroll-menu-arrow {
    display: none !important;
  }
  .s2 .help-text {
    display: inline-block !important;
  }
  .section-heading {
    font-size: 2em;
  }
}

/*Small Screens  */
@media screen and (max-width: 800px) {
  .mobile-horizontal-scroll {
    display: block;
  }
  .horizontal-scroll {
    display: none;
  }

  .intro-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "nav-wrapper"
      "right-column"
      "left-column"
      "right-column2";
  }

  .contact-section-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "contact-left-div"
      "contact-right-div";
  }

  .video-card {
    min-height: 280px;
  }

  .right-column {
    justify-content: center;
  }
  .right-column2 {
    /* transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    ) !important; */
  }
  .about-section-body {
    grid-template-columns: 1fr;
    grid-template-areas:
      "left"
      "right";
  }
}
@media only screen and (max-width: 480px) {
  .main-container {
    width: 100%;
  }
  #g-recaptcha {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  .section-about {
    background-attachment: scroll;
  }
}
