.horizontal-scroll-menu {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display:flex;
  -webkit-overflow-scrolling: touch;
}
.horizontal-scroll-menu .video-card {

  padding: 14px;
}

#horizontal-menu::-webkit-scrollbar-thumb
{
	background-color: #888888;
}
#horizontal-menu::-webkit-scrollbar{
    height: 7px;

}